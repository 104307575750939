import * as React from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material"; 
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';


export default function Control(props) {

  function controlToggleDispatch(prevState, event) {
    let newState = {...prevState}
    newState[event.target.name]["active"] = event.target.checked
    return newState
  }

  const [controlToggles, setControlToggles] = React.useState({
    heatmap: {
      active: true, 
      enabled: true,
      displayName: "Heatmap",
      toggleHandler: (event) => {
        props.mapRef.current.getMap().setLayoutProperty('heldk-bqxkfj','visibility', event.target.checked ? 'visible' : 'none')
        setControlToggles(prevState => controlToggleDispatch(prevState, event))
      },
    },
    mtbTrails: {
      active: true,
      enabled: true,
      displayName: "MTB trails",
      toggleHandler: (event) => {
        let currentPaint = props.mapRef.current.getMap().getPaintProperty("hele-danmark-utf8-81olz2 copy","line-color")
        currentPaint[2] = currentPaint[2].substr(0,currentPaint[2].length - 2) + (event.target.checked ? "1)" : "0)" )
        props.mapRef.current.getMap().setPaintProperty("hele-danmark-utf8-81olz2 copy","line-color", currentPaint)
        setControlToggles(prevState => controlToggleDispatch(prevState, event))
      },
    },
    verifiedTracks: {
      active: false,
      enabled: true,
      displayName: "Colorblind",
      toggleHandler: (event) => {
        /* TODO: create proper style switching */
        let colorMap = {
          2: 348,
          6: 8,
          8: 22,
          10: 120
        }

        let currentPaint = props.mapRef.current.getMap().getPaintProperty("hele-danmark-utf8-81olz2 copy","line-color")
        for (var colorIndex in colorMap) {
          currentPaint[colorIndex] = currentPaint[colorIndex].substr(0,4) + (event.target.checked ? 250 : colorMap[colorIndex]) + currentPaint[colorIndex].substr(currentPaint[colorIndex].indexOf(','))
        }
        
        props.mapRef.current.getMap().setPaintProperty("hele-danmark-utf8-81olz2 copy","line-color", currentPaint)
        setControlToggles(prevState => controlToggleDispatch(prevState, event))
      },
    }
  });



  function valuetext(value) {
    return <Typography variant="body2" sx={{Size:"0.8em"}}>`${value}°C`</Typography>;
  }

  const [rangeValue, setRangeValue] = React.useState([0, 3]);

  const handleRangeChange = (event, newValue) => {
    setRangeValue(newValue);
  };

  let toggleSwitches = []
  for (const [key, value] of Object.entries(controlToggles)) {
    toggleSwitches.push(<FormControlLabel
      key={key}
      sx={{mr:0}}
      control={
          <Switch
          sx={{mx:0.5}}
          size='small'
          disabled={!value.enabled}
          checked={controlToggles[key].active}
          onChange={value.toggleHandler}
          name={key}
          />
      }
      label={<Typography variant='body2'>{value.displayName}</Typography>}
  />)
  }

  return (

        <Box width={props.width} sx={{p:0}}>
          <Accordion defaultExpanded={!props.defaultCollapsed}>
            
          <AccordionSummary
            expandIcon={props.defaultCollapsed && <ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
          <FormLabel component="legend" sx={{mr:0}}>
                
          <Typography variant='body'>Map features</Typography>      
                
                </FormLabel>
          
            </AccordionSummary>
            <AccordionDetails sx={{mt:-3}}>
          
            <FormControl component="fieldset" variant="standard">
            <FormGroup>
                {toggleSwitches}
            </FormGroup>
            </FormControl>
            <FormLabel sx={{mt:1,mb:-0.8}} component="legend" align="center">
            <Typography variant='body' >Road condition</Typography>
            </FormLabel>
            <Stack
            spacing={2}
            direction="row"
            sx={{ mt: 0.5, mb: -0.8, mx: 1 }}
            alignItems="center"
            >
            <Slider
            size="small"
            disabled={true}
                getAriaValueText={valuetext}
                xs={{fontSize:"0.8em"}}
                step={1}
                max={3}
                value={rangeValue}
                valueLabelDisplay="auto"
                onChange={handleRangeChange}
            />
            </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
    
  );
}
