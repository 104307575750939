import React, {useState} from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from "@mui/material/Divider";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Collapse from '@mui/material/Collapse';
import axios from "axios"
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { HTMLOverlay } from '!react-map-gl'; // eslint-disable-line import/no-webpack-loader-syntax

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function surfaceDescription(info) {
    if (info.waytype) {
        return info.surface
    }
    const primary_surface = ["unpaved", "compacted", "fine_gravel", "gravel", "pebblestone",
        "dirt", "earth", "ground", "woodchips", "clay", "sand", "grass"]

    if (primary_surface.indexOf(info.surface) > -1) {
        return capitalizeFirstLetter(info.surface).replace('_', ' ')
    }

    return 'Unpaved'
}

export function waytypeDescription(info) {
    if (info.waytype) {
        return info.waytype
    }
    // track, path or road
    var waytype
    switch (info.highway) {
        case 'path':
            waytype = 'Path'
            break;
        case 'track':
            waytype = 'Track'
            break;
        case 'bridleway':
            waytype = 'Bridleway'
            break;
        case 'cycleway':
            waytype = 'Track'
            break;
        case 'footway':
            waytype = 'Path'
            break;
        case undefined:
            waytype = 'Unknown'
            break;
        default:
            waytype = 'Track'
    }

    if (['grade4', 'grade5', 'grade3-5'].indexOf(info.tracktype) > -1) {
        waytype = 'Path'
    }

    if (info["mtb"] || info["mtb:scale"]) {
        if (info.mtb !== 'no') {
            waytype = 'Trail'
        }
    }

    if (waytype === 'Path') {
        if (['horrible', 'very horrible', 'impassable'].indexOf(info.smoothness) > -1) {
            waytype = 'Trail'
        }
    }

    return waytype
}

export function rideabilityIndex(info) {

    if (info.ri) {
        return info.ri
    }

    var ri = 3

    if (["cycleway",
        "service",
        "living_street",
        "residential",
        "tertiary",
        //"path",
        //"track",
        "unclassified"].indexOf(info.highway) > -1) {
        ri = 4
    }

    if (['grade4', 'grade5', 'grade3-5'].indexOf(info.tracktype) > -1) {
        ri = 2
    }

    if (['sand', 'grass'].indexOf(info.surface) > -1) {
        ri = 1.5
    }

    switch (info.smoothness) {
        case 'horrible':
            ri = 2.5
            break;
        case 'very horrible':
            ri = 2
            break;
        case 'impassable':
            ri = 1
            break;
        default:
            break;
    }

    return ri
}

function wayIcons(info) {
    return []
}


function TopButtomLine(props) {
    var Top
    if (props.tooltip) {
        Top = (<>
            <Typography variant="overline" color="text.secondary">
                {props.top}
            </Typography>
            <Tooltip title={props.tooltip} placement="right">
                <InfoOutlinedIcon sx={{ fontSize: 12, pt: 0.2 }} color="action" />
            </Tooltip></>)
    } else {
        Top = (<Typography variant="overline" color="text.secondary">
            {props.top}
        </Typography>)
    }
    return (<>          {Top}
        <Typography sx={{ mt: -1.8 }} variant="body2">
            {props.buttom}
        </Typography>
    </>)
}


function FeatureInfo(props) {
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false)
    if (props.feature.latitude === undefined) {
        return <div></div>
    }

    return (
        <HTMLOverlay
            redraw={
                ({ project }) => {

                    var coords = project([props.feature.longitude, props.feature.latitude])

                    return (
                        // Move to own component
                        // Surface: grass
                        // MI (tooltip maneuverability index): 5.4/10
                        // Waytype: path (track, "road?")
                        // Icons + tooltip - footpath, briddleway, no bike!!!, bike maybe? Dismount required
                        // Actions: Mark issue, suggest change

                        <Card sx={{
                            width: `${props.width}px`,
                            left: `${coords[0] - props.width / 2}px`,
                            top: `${coords[1]}px`,
                            position: "absolute",
                        }}
                            raised={true}
                        >
                            <div className="Mui">
                                <IconButton className="Mui" onClick={() => { props.onClose() }} sx={{ p: 0, my: 0 }}>
                                    <CloseRoundedIcon className="Mui" />
                                </IconButton>
                            </div>

                            <CardContent sx={{
                                mt: -4,
                                mb: -2,
                                p: 1,
                            }}> <Typography variant="overline" sx={{ fontWeight: "bold", fontSize: "0.7em" }} color="text.secondary">
                                    attributes
                                </Typography>
                                <Grid sx={{ mt: -1 }}>
                                {props.feature.properties.deleteMarked ? <TopButtomLine buttom={`Marked for deletion`} top="Status" />:
                                    <>
                                    <TopButtomLine buttom={`${rideabilityIndex(props.feature.properties)}/5`} top="RI" tooltip="Ride-ability Index (Smoothness)" />
                                    <TopButtomLine buttom={waytypeDescription(props.feature.properties)} top="Waytype" />
                                    <TopButtomLine buttom={surfaceDescription(props.feature.properties)} top="Surface" />
                                  </>}  
                                </Grid>
                                {wayIcons(props.feature.properties).map((icon) => { return icon })}
                            </CardContent>
                            {props.feature.properties.edited && (
                                <>
                                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                                    <CardContent sx={{
                                        mt: 0,
                                        mb: -3,
                                        p: 1,
                                    }}> <Divider>
                                            <Typography variant="overline" sx={{ fontWeight: "bold", fontSize: "0.8em" }} color="text.secondary">
                                                Original
                                            </Typography>
                                        </Divider>

                                        <Grid sx={{ mt: -1.5 }}>

                                            {((props.feature.properties['old ri'] !== props.feature.properties['ri']) || props.feature.properties.deleteMarked) && <TopButtomLine buttom={`${props.feature.properties['old ri']}/5`} top="RI" tooltip="Ride-ability Index (Smoothness)" />}
                                            {((props.feature.properties['old waytype'] !== props.feature.properties['waytype']) || props.feature.properties.deleteMarked) && <TopButtomLine buttom={props.feature.properties['old waytype']} top="Waytype" />}
                                            {((props.feature.properties['old surface'] !== props.feature.properties['surface']) || props.feature.properties.deleteMarked) && <TopButtomLine buttom={props.feature.properties['old surface']} top="Surface" />}
                                            {<TopButtomLine buttom={`${props.feature.properties.verifications || 0} times`} top="Verified" />}
                                        </Grid>
                                    </CardContent>
                                    </Collapse>
                                    <CardActions sx={{ justifyContent: "center", mt: 1, mb:0.5 }}>
                                        <LoadingButton loading={loading} variant={expanded ? "contained" : "outlined"} size="small" onClick={()=>{
                                            if (expanded){
                                                setLoading(true)
                                                console.log(props.feature)
                                                const post = axios.post(`https://outforsk-api.azurewebsites.net/api/verifyedit/${props.feature.properties.internalId}?code=NQ8sZgO0aroBwoLIfL4kGQQ8LIzBxfOSqNQCd1ptJMdD6178HxzOkw%3D%3D`)
                                                setLoading(false)
                                                post.then(setExpanded(false))
                                            } else
                                            {   
                                                setExpanded(true)
                                            }
                                        }} >{expanded ? "Verify changes" : "View changes"}</LoadingButton>
                                    </CardActions>
                                </>
                            )

                            }
                        </Card>
                    )
                }
            } />
    )
}

export default FeatureInfo
