import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
//import LoginButton from './LoginButton'
//mport LogoutButton from './LogoutButton'

export default function HeaderBar() {
  return (
    <AppBar position="sticky" color="primary">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Outforsk
            </Typography>
            {/*<LoginButton/>
            <LogoutButton/>*/}
          </Toolbar>
          
    </AppBar>
  )
}
