import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CardActions from '@mui/material/CardActions';
import Slider from "@mui/material/Slider";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import { useState } from 'react';
import axios from "axios"
import DeleteIcon from '@mui/icons-material/Delete';


function RoadChangeSubmitBox(props) {
    var waytypes = ['Track','Path','Trail']
    var surfaces = ["Compacted gravel", "Gravel", "Dirt", "Sand", "Grass", "Unpaved"]
    
    const [wayInfo, setWayInfo] = React.useState(waytypes.includes(props.wayInfo) ?  props.wayInfo : waytypes[0]);
    const [surfaceInfo, setSurfaceInfo] = React.useState(surfaces.includes(props.surfaceInfo) ? props.surfaceInfo : surfaces[1]);
    const [riInfo, setRiInfo] = React.useState(props.riInfo || 3);
        
    const handleWayChange = (event) => {
        setWayInfo(event.target.value);
    };
    
    const handleSurfaceChange = (event) => {
        setSurfaceInfo(event.target.value);
    };

    const handleRiChange = (event, newValue) => {
        setRiInfo(newValue);
    };

    const [loading, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false)
  
    var arrayToMenuItems = (item)=>{return <MenuItem value={item} sx={{fontSize:'0.9em'}} key={item}>{item}</MenuItem>}
    return (
               
                <Box sx={{
                  left:`calc(${props.mobileView ? 50: 70}% - 125px)`,
                  top: props.mobileView ? 8 : '20%', 
                  position:"absolute"
                  }}
                  >
                    <Paper sx={{ maxWidth: 250,p:1 }}>
                        <Box sx={{p:0.5}}>
                    <Typography variant="caption">
                        Fill in details for the road you want to add to the map.
                    </Typography>
                    </Box>
                    <FormControl variant="filled" size="small" sx={{minWidth:"100%", my:1}}>
                    <InputLabel id="demo-simple-select-filled-label">Waytype</InputLabel>
                    <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={wayInfo}
                    onChange={handleWayChange}
                    sx={{fontSize:'0.9em',width:'100%'}}
                    >
                        {waytypes.map(arrayToMenuItems)}
                    </Select>
                    </FormControl>
                    <Box sx={{px:0.5}}>
                    <Typography variant="body2" sx={{fontSize:"0.8em"}}>
                        <b>Tracks</b> are wide enough for cars
                    </Typography>
                    <Typography variant="body2" sx={{fontSize:"0.8em"}}>
                    <b>Trails</b> are for going one-by-one
                    </Typography>
                    <Typography variant="body2" sx={{fontSize:"0.8em"}}>
                    <b>Paths</b> are everything in-between
                    </Typography>
                    </Box>
                    <Divider sx={{mt:1}}/>
                    <FormControl variant="filled" size="small" sx={{minWidth:"100%", mt:1}}>
                    <InputLabel id="demo-simple-select-filled-label">Surface</InputLabel>
                    <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={surfaceInfo}
                    onChange={handleSurfaceChange}
                    sx={{fontSize:'0.9em', width:'100%'}}
                    >
                        {surfaces.map(arrayToMenuItems)}
                    </Select>
                </FormControl>
                <Divider sx={{mt:1}}/>
                <FormLabel sx={{mt:1,mb:0,px:0.5}} component="legend" align="center">
                <Typography variant='body2' >RI (smoothness)</Typography>
                </FormLabel>
                <Typography variant="body2" sx={{px:0.5, fontSize:"0.8em"}}>
                        RI measures how fast the surface is. 5 is close to asphalt, where 1 is rugged, rough and probably calls for walking or an MTB.
                    </Typography>
                <Stack
            spacing={2}
            direction="row"
            sx={{ mt: 0.5, mb: 0, mx: 1 }}
            alignItems="center"
            >
            <Slider
                    size="medium"
                    xs={{fontSize:"0.8em"}}
                    step={0.5}
                    
                    max={5}
                    min={1}
                    marks={[
                        {value:1,label:'1'},
                        {value:2,label:'2'},
                        {value:3,label:'3'},
                        {value:4,label:'4'},
                        {value:5,label:'5'}
                ]}
                    value={riInfo}
                    valueLabelDisplay="auto"
                    onChange={handleRiChange}
                />
            </Stack>
                
                    <CardActions sx={{px:1}}>
                        <Box sx={{ flexGrow: 1 }}>
                        <LoadingButton sx={{width:'100px'}} loading={loading} size="small" variant="contained" onClick={()=>{
                            setLoading(true)
                            var docToPost = props.genDoc(props.candidate, wayInfo, surfaceInfo, riInfo)
                            
                            const post = axios.post(props.postUrl,docToPost)
                            post.then(props.onPost(docToPost, props))
                            setLoading(false)
                        }}>Submit</LoadingButton>
                        </Box>
                        <Button sx={{width:'100px'}} size="small" variant="contained" onClick={props.resetCandidate}>Cancel</Button>
                    
                    </CardActions>
                    {props.deletableObject && (
                        <Box sx={{textAlign: 'center', width:'100%'}} justify="center">    
                            <LoadingButton 
                                loading={deleting} 
                                onClick={()=>{
                                    setDeleting(true)
                                    var docToPost = props.genDoc(props.candidate, wayInfo, surfaceInfo, riInfo)
                                    docToPost.properties['deleteMarked'] = true
                                    const post = axios.post(props.postUrl,docToPost)
                                    post.then(props.onPost(docToPost, props))
                                    setDeleting(false)
                                }} 
                                sx={{width: '220px', my:0.8}} 
                                size="small" 
                                variant="outlined" 
                                color="error" 
                                startIcon={<DeleteIcon />}>
                                 Mark road as paved
                            </LoadingButton>
                        </Box>
                    )}
                    </Paper>
                    
                </Box>
            )
                }

export default RoadChangeSubmitBox
