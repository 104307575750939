import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import * as turf from "@turf/turf";
import Box from '@mui/material/Box';
import { useState } from 'react';
import queryOverpass from 'query-overpass'
import {HTMLOverlay} from '!react-map-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import getTextWidth from '../utils'

function setStateToClosestOSMDataToPoint(longitude, latitude, stateSetter, setLoading, onFinish){
        const query = `[out:json];
        way(around:20, ${latitude},${longitude});
        (._;>;);
        out qt;`;
        setLoading(true)
        const options = {
            flatProperties: true,
            overpassUrl: 'https://overpass-api.de/api/interpreter'
          };
        var dataHandler = (error, osmData) => {
            if (!error && osmData.features !== undefined) {    
                var clickPoint = turf.point([longitude, latitude])
                
                osmData.features = osmData.features.filter((feature) => feature.geometry.type.endsWith('LineString') )
                var dist = osmData.features.map((feature) => {return turf.pointToLineDistance(clickPoint, feature.geometry.coordinates)})
                
                var minIndex
                var minDist = 10**3
                dist.forEach(function(cur_dist, index) {
                    if(cur_dist < minDist){
                        minIndex = index
                        minDist = cur_dist
                    }
                })
                osmData.features = [osmData.features[minIndex]]
                
                stateSetter(osmData)
                setLoading(false)
                onFinish()
                //https://github.com/visgl/react-map-gl/blob/6.1-release/examples/viewport-animation/src/app.js#L19
                
            }
          };
        queryOverpass(query, dataHandler, options)
    }

function MissingRoadButton(props) {

  const [loading, setLoading] = useState()
    if (props.latitude === undefined){
        return <div></div>
      }
    

    return (
        <HTMLOverlay
          redraw={
            ({project}) => {
                const width = 183 + (props.properties.name !== undefined ? getTextWidth(`${props.properties.name}`, 'normal 11pt Roboto') : 0)
                
                var coords = project([props.longitude, props.latitude ])
              
              return (
               
                <Box sx={{
                  left:`${coords[0]-width/2}px`,
                  top:`${coords[1]+5}px`,
                  position:"absolute",
                  backgroundColor:'beige'
                  }}
                  >
                    <LoadingButton 
                      loading={loading}
                        onClick={() => {setStateToClosestOSMDataToPoint(props.longitude, props.latitude, props.candidateHandler, setLoading, props.onClose)}}
                        size="small" 
                        variant="contained">
                            Add {props.properties.name} as missing gravel way
                    </LoadingButton>
                </Box>
            )
            }
          }/>
          
    )
}

export default MissingRoadButton
