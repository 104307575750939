import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Sidebar from './mapComponents/Sidebar'
import HeaderBar from './components/HeaderBar';
import MapContainer from './mapComponents/MapContainer';
import { useState, useEffect } from 'react';
import LoginPage from './components/LoginPage';
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery';

const theme = createTheme({
  palette: {

    type: 'light',

    primary: {

      main: '#457e2a',//#9cd66d

    },

    secondary: {

      main: '#fff59d',

    },

    background: {

      paper: 'beige',

      default: '#f9fbe7',

    },

  },typography: {
    button: {
      textTransform: "none"
    },
    
  }
});


const Main = styled('div')(({ theme }) => ({
  
  padding: theme.spacing(0),

  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 56px)',
  },
  [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
    height: 'calc(100vh - 48px)',
  },
  [theme.breakpoints.up('sm')]: {
    height: 'calc(100vh - 64px)',
  },
}));

export default function App() {

  const isAuthenticated = true
  const [mapInfoWidth, setMapInfoWidth] = useState('0px')
  const [userLocation, setUserLocation] = useState('')
  const sidebarWidth = '175px'
  const [mapActive, setMapActive] = useState(true)
  

  const sidebarClickHandler = (event) => {
    setMapInfoWidth(event.target.innerText === 'My profile' ? '100vw - 175px' : '0px')
    setMapActive(event.target.innerText === 'My profile' ? false : true)
    
  }

  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  
  useEffect(() => {
    try{
      fetch('https://ipapi.co/json/', {method: 'get'})
        .then((response) => response.json())
        .then((data) => {
          const {latitude, longitude } = data
          if (latitude === 0 || latitude === undefined){
            setUserLocation({latitude: 56.2639,longitude: 11,zoom: 6.5})
          }
          setUserLocation({
            latitude: latitude,
            longitude: longitude,
            zoom:10
          })
        })}
      catch{
        setUserLocation({latitude: 56.2639,longitude: 11,zoom: 6.5})
      }
      
  }, [])
  
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <HeaderBar/>
        
        
        <Main>
          {!isAuthenticated && (
            <LoginPage/>
          )}
          {isAuthenticated && userLocation && (<Grid container height='100%'>
            <Grid item>
              {(!mobile) && <Sidebar width={sidebarWidth} clickHandler={sidebarClickHandler}/>}
            </Grid>
            <Grid item style={{'width':`calc(100vw - ${mapInfoWidth} - ${mobile ? "0px" : sidebarWidth})`, 'position': 'relative'}}>
                <MapContainer {...userLocation}
                shouldUpdate={false}
                active={mapActive}
                showSearchBar={!mobile}
                collapsedMapFeatures={mobile}
                updateDefaultLocation={(data) => console.log(data)}/>
            </Grid>
            {mapInfoWidth !== '0px' && (<Grid item style={{'width':`calc(${mapInfoWidth})`}}>
              <Typography>"No data"</Typography>
            </Grid>)}
          </Grid>)}
        </Main>
      </ThemeProvider>
  );
}