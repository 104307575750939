import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';

import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import ExploreTwoToneIcon from '@mui/icons-material/ExploreTwoTone';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';

const theme = createTheme();


export default function Sidebar(props) {
    const menuItems = [
        {
            'text': 'My profile',
            'icon': <AccountCircleTwoToneIcon/>,
            'active': false
        },
        {
            'text': 'Map',
            'icon': <MapTwoToneIcon/>,
            'active': true
        },
        {
            'text': 'Zones',
            'icon': <ExploreTwoToneIcon/>,
            'active': false
        },
        {
            'text': 'Activies', //create subfilter on map
            'icon': <PedalBikeIcon/>,
            'active': false
        },
        {
            'text': 'Social', //friends clubs followers
            'icon': <RecentActorsTwoToneIcon/>,
            'active': false
        },
        
    ]
    
    return (
        <Box sx={{
            height:'100%',
            flexDirection: "column",
            display: "flex",
            p: 1,
          }}
            width={props.width}  backgroundColor='beige'>
            <Box>
            <List sx={{flexGrow: 1}}>
                {menuItems.map((item, index) => (
                <ListItem button disabled={!item.active} key={item.text} onClick={props.clickHandler}>
                    <ListItemIcon style={{'minWidth': theme.spacing(5)}}>
                        {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text}/>
                    
                </ListItem>
                ))}
                
            </List>
            </Box>
            <Box sx={{flexGrow:1}}/>
            <Box>
            </Box>
        </Box>
    )
}
