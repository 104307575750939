import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapGL, { FlyToInterpolator, GeolocateControl, Layer, Source } from '!react-map-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import Geocoder from "!react-map-gl-geocoder"; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useCallback, useRef, useState, useEffect } from 'react';
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import FeatureInfo from "./FeatureInfo";
import InfoBox from "./InfoBox";
import MissingRoadButton from "./MissingRoadButton";
import MissingRoadSubmitBox from "./RoadChangeSubmitBoxes/MissingRoadSubmitBox";
import EditRoadSubmitBox from "./RoadChangeSubmitBoxes/EditRoadSubmitBox";
import SetHome from "./SetHome";
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = 'pk.eyJ1IjoiaGFybXV0aCIsImEiOiJja25vY3owcm8xN3BzMnZwcm1pZGVwazU0In0.YKMomeHxxTjkrrX9j9svPQ';
const MAPBOX_TOKEN = 'pk.eyJ1IjoiaGFybXV0aCIsImEiOiJja25vY3owcm8xN3BzMnZwcm1pZGVwazU0In0.YKMomeHxxTjkrrX9j9svPQ'; // Set your mapbox token here

function pointerOverMUI(event){
  
  if (event.target.getAttribute("class") !== null)
          { 
            if (event.target.getAttribute("class").includes('Mui')){
              return true
          }}
  return false
}

function getRandom (list) {
  return list[Math.floor((Math.random()*list.length))];
}

export default function MapContainer(props) {
  
  const mapRef = useRef();
  const [viewport, setViewport] = useState({
    longitude: props.longitude,
    latitude: props.latitude,
    zoom: props.zoom
  });

  

  const [locationMode, setLocationMode] = useState(props.shouldUpdate ? "setLocation" : "Confirmed")
  const [defaultCenter, setDefaultCenter] = useState()
  const [clickedFeature, setClickedFeature] = useState()
  const [showAddMissingWay, setShowAddMissingWay] = useState()
  const [missingCandidate, setMissingCandidate] = useState()
  const [editCandidate, setEditCandidate] = useState()
  const [noneTileSetData, setNoneTileSetData] = useState()
  const [editedRoads, setEditedRoads] = useState({type:'FeatureCollection', features:[]})
  //const [mode, setMode] = useState() // addMissing, editRoad, showingFeature

  useEffect(() => {
    try{
      fetch('https://outforsk-api.azurewebsites.net/api/getNonTileGeojson?code=KySpPQF7mrXlCilWfWDGPlZ/wYCKdPAHZvaaZHtx/dB0VzKWK2VBpg==', {method: 'get'})
        .then((response) => response.json())
        .then((data) => {
          setNoneTileSetData(data)
        })
        fetch('https://outforsk-api.azurewebsites.net/api/geteditedroadsgeojson?code=XQlsK121MzTpvVw0Xckcvpj%2FyTQWb3GsF4Nd1Io10cdJWeUjVNdlbA%3D%3D', {method: 'get'})
        .then((response) => response.json())
        .then((data) => {
          for (let i = 0; i < data.features.length; i++) { 
            data.features[i].properties.internalId = data.features[i].id
          }
          setEditedRoads(data)
        })
      }
      catch{
            
    }
      
    
  }, [])
  const geolocateStyle = {
    top: 0,
    left: 0,
    margin: 10,
    width: '30px'
  };
  const positionOptions = {enableHighAccuracy: true};
  
  function setHomeClickHandler(event) {
    
    if (locationMode === 'setLocation'){
      
      
      const {_ne, _sw} = mapRef.current.getMap().getBounds()
      const center = {longitude: (_ne.lng+_sw.lng)/2,
                      latitude: (_ne.lat+_sw.lat)/2,
                      zoom: 11.5}
      
      setViewport({
        ...center,
        transitionInterpolator: new FlyToInterpolator({speed: 2}),
        transitionDuration: '100',
        })
      
      setTimeout(() => {
        setLocationMode('confirmLocation')
          setDefaultCenter(center)
      },120)
    } else {
      setLocationMode('Confirmed')
      props.updateDefaultLocation(defaultCenter)
    }
  }

  const handleViewportChange = useCallback(
    (newViewport) => {
      
      if (locationMode === 'confirmLocation'){
        
      if( defaultCenter.latitude !== newViewport.latitude
      || defaultCenter.longitude !== newViewport.longitude
      || defaultCenter.zoom !== newViewport.zoom){
        setLocationMode("setLocation")
      }
      
    }
    setViewport(newViewport)
  },
    [locationMode, defaultCenter]
  );

  

  function onHover(event) {
    var isHovering = false
    if (event.features !== undefined){
      const filteredFeatures = event.features.filter(feature => ['hele_danmark_utf8-81olz2', 'road'].includes(feature.sourceLayer))
      if (filteredFeatures.length > 0 && !pointerOverMUI(event)){
        isHovering = true
      }
    }
    mapRef.current.setState({'isHoveringExpanded':isHovering})
  }
  
function customCursor({isDragging, isHoveringExpanded}){
  return isDragging ? 'dragging' : (isHoveringExpanded ? 'pointer': 'default')
}

const nonTilesetLayer = {
  "id": "nomeTilesetLayer",
  "type": "line",
  "minzoom": 9,
  "layout": {
      "line-join": "round",
      "line-cap": "round",
      "line-round-limit": 1
  },
  "paint": {
      "line-color": [
          "case",
          ["match", ["get", "mtb"], ["designated"], true, false],
          "hsl(348, 56%, 63%)",
          [
              "match",
              ["get", "surface"],
              ["Sand", "Grass"],
              true,
              false
          ],
          "#025e47",
          ["<=",["get", "ri"],2],
          "hsl(8, 100%, 33%)",
          "hsl(22, 90%, 53%)",
      ],
      "line-opacity": [
          "interpolate",
          ["exponential", 1.26],
          ["zoom"],
          8,
          0,
          11,
          1,
          22,
          1
      ],
      "line-width": [
          "interpolate",
          ["exponential", 1.1],
          ["zoom"],
          9,
          0,
          21.138,
          [
              "case",
              ["boolean", ["in", ["get", "id"], ""], false],
              15,
              9
          ]
      ],
      "line-gap-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          9,
          0,
          16,
          [
              "case",
              [
                  "match",
                  ["get", "waytype"],
                  [
                      "Track"
                  ],
                  true,
                  false
              ],
              [
                  "case",
                  ['<=',['get','ri'],2],
                  0,
                  1
              ],
              0
          ]
      ],
      "line-translate": [0, 0],
      "line-blur": 0.2
  }
}

const dataLayer = {
  id: 'candidates-for-missing-road',
  type: 'line',
  paint:{
    'line-width':[
      "interpolate",
      ["exponential", 1.1],
      ["zoom"],
      9,
      0,
      21.138,
      [
        "case",
        [
          "boolean",
          [
            "in",
            ["get", "id"],
            ""
          ],
          false
        ],
        15,
        8
      ]
    ],
    'line-color':'beige'},
  layout:{
    'line-cap':'round',
    'line-join':'round'
    }
  };
  const dataLayerCase = {
    id: 'candidates-for-missing-road-case',
    type: 'line',
    paint:{
      'line-width':[
        "interpolate",
        ["exponential", 1.1],
        ["zoom"],
        9,
        0,
        21.138,
        [
          "case",
          [
            "boolean",
            [
              "in",
              ["get", "id"],
              ""
            ],
            false
          ],
          15,
          20
        ]
      ],
      'line-color':'#457e2a'},
    layout:{
      'line-cap':'round',
      'line-join':'round'
      }
    };
  
const editedLayer = {
    "id": "editedLayer",
    "type": "line",
    "minzoom": 13.5,
    "layout": {
        "line-join": "round",
        "line-cap": "round",
        "line-round-limit": 1
    },
    "paint": {
        "line-color": "black",
        "line-opacity": [
            "interpolate",
            ["exponential", 1.26],
            ["zoom"],
            8,
            0,
            11,
            1,
            22,
            1
        ],
        "line-width": [
            "interpolate",
            ["exponential", 1.1],
            ["zoom"],
            9,
            0,
            21.138,
            [
                "case",
                ["boolean", ["in", ["get", "id"], ""], false],
                15,
                9
            ]
        ],
        "line-gap-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            9,
            0,
            16,
            [
                "case",
                [
                    "match",
                    ["get", "waytype"],
                    [
                        "Track"
                    ],
                    true,
                    false
                ],
                [
                    "case",
                    ['<=',['get','ri'],2],
                    0,
                    1
                ],
                0
            ]
        ],
        "line-translate": [0, 0],
        "line-dasharray": [2, 4]
    }
  }
  
  return (
    <>
          <Box sx={{
                  left:8,
                  top: 8, 
                  position:"absolute",
                  width:"800px"
                  }}
                  >
                    <Typography variant="h7" >
                        If you see this text you most likely have an adblocker or are using the Brave browser. The map tool Mapbox unfortunately doens't work with these.
                        If you have adblocker, you can disable it (no ads are being shown!)
                    </Typography>
          </Box>

      <MapGL
        {...viewport}
        ref={mapRef}
        width="100%"
        height="100%"
        onHover={onHover}
        getCursor={customCursor}
        onViewportChange={handleViewportChange}
        mapStyle="mapbox://styles/harmuth/cknq8wd6p11hy17rvwy1i117g"
        
        onClick={(event)=>{
          
          if(pointerOverMUI(event)){
            return
          }

          // Case missing candidat layer

          if(missingCandidate) {
            //pass
          }
          else if (editCandidate){
            //pass
          }
          else {
            // Case outforsk layer
            console.log(event.features)
            const filteredFeatures = event.features.filter(feature => feature.sourceLayer === 'hele_danmark_utf8-81olz2' || ['nonTileSetData','editedRoads'].includes(feature.source) )
            if (filteredFeatures.length>0){
              const prio1 = filteredFeatures.filter(feature => 'editedRoads' === feature.source)
              const prio2 = filteredFeatures.filter(feature => 'nonTileSetData' === feature.source)

              var feature = prio1.length > 0 ? getRandom(prio1) : prio2.length>0 ? getRandom(prio2) : filteredFeatures[0]

              feature['latitude'] = event.lngLat[1]
              feature['longitude'] = event.lngLat[0]
              setClickedFeature(feature)
              setShowAddMissingWay()
            } else {
              setClickedFeature()
              // case road layer
              const filteredRoads = event.features.filter(feature => feature.sourceLayer === 'road')
              if (filteredRoads.length> 0){
                  var road = filteredRoads[0]
                  road['latitude'] = event.lngLat[1]
                  road['longitude'] = event.lngLat[0]
                  setShowAddMissingWay(road)
              } else {
                setShowAddMissingWay()
              }
            }
          }
        }}
        mapboxApiAccessToken={MAPBOX_TOKEN}
      >
        {missingCandidate &&
        <Source type="geojson" data={missingCandidate}>
          <Layer {...dataLayerCase} />
          <Layer {...dataLayer} />
        </Source>}
        {editCandidate &&
        <Source type="geojson" data={editCandidate}>
          <Layer {...dataLayerCase} />
          <Layer {...dataLayer} />
        </Source>}
        {noneTileSetData && 
        <Source type="geojson" data={noneTileSetData} id='nonTileSetData'>
          <Layer {...nonTilesetLayer} id='nonTileSetData'/>
        </Source>
        }
        {editedRoads && 
        <Source type="geojson" data={editedRoads} id='editedRoads'>
          <Layer {...editedLayer} id='editedRoads'/>
        </Source>
        }
        {props.showSearchBar ? <Geocoder
          mapRef={mapRef}
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          position="top-left"
          marker={false}
          collapsed={true}
        /> : <GeolocateControl
          style={geolocateStyle}
          positionOptions={positionOptions}
          trackUserLocation
          auto
        />}
        {clickedFeature && <FeatureInfo feature={clickedFeature} width={135} setEdit={setEditCandidate} onClose={setClickedFeature}/>}
        {showAddMissingWay && <MissingRoadButton {...showAddMissingWay} candidateHandler={setMissingCandidate} onClose={setShowAddMissingWay}/>}
        
      </MapGL>
      {props.active && <InfoBox defaultCollapsed={props.collapsedMapFeatures} mapRef={mapRef}/>}
      {missingCandidate && <MissingRoadSubmitBox setNoneTileSetData={setNoneTileSetData} mapRef={mapRef} mobileView={props.collapsedMapFeatures} resetCandidate={()=>{setMissingCandidate()}} candidate={missingCandidate}/>}
      {editCandidate && <EditRoadSubmitBox setEditedData={setEditedRoads} mapRef={mapRef} mobileView={props.collapsedMapFeatures} resetCandidate={()=>{setEditCandidate()}} candidate={editCandidate}/>}
      {locationMode !== "Confirmed" && <SetHome onClick={setHomeClickHandler} buttonText={(locationMode === 'setLocation') ? "Set location" : "Confirm location"}/>}
    </>
  );
}
