import RoadChangeSubmitBox from './RoadChangeSubmitBox'
import {rideabilityIndex, waytypeDescription, surfaceDescription} from '../FeatureInfo'
import React from 'react'

function MissingRoadSubmitBox(props) {
    var genDoc = (candidate, wayInfo, surfaceInfo, riInfo) => {
        var docToPost = {...candidate.features[0]}
        docToPost.properties['surface'] = surfaceInfo
        docToPost.properties['waytype'] = wayInfo
        docToPost.properties['ri'] = riInfo
        delete docToPost['id']
    return docToPost}
    
    const candidateFeature = props.candidate.features[0]
    const defaultSurface = surfaceDescription(candidateFeature.properties)
    const defaultWay = waytypeDescription(candidateFeature.properties)
    const defaultRi = rideabilityIndex(candidateFeature.properties)
    
    const onPost = (docToPost, _props) => {
        _props.setNoneTileSetData((previous)=>{    
            docToPost['id'] = docToPost.properties['id']
            previous.features.push(docToPost)
            _props.mapRef.current.getMap().getSource('nonTileSetData').setData(previous)
            return previous
        })
        _props.resetCandidate()
    }
    

    return (
        <RoadChangeSubmitBox 
            wayInfo={defaultWay}
            surfaceInfo={defaultSurface}
            riInfo={defaultRi}
            {...props}
            onPost={onPost}
            postUrl="https://outforsk-api.azurewebsites.net/api/createMissingWays?code=A93qEpxkvjojF7D0G45vVq1hHbNYLobnOauguNM2YcFB7zlPeP6frQ=="
            genDoc={genDoc}/>
    )
}

export default MissingRoadSubmitBox
