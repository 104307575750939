import Legend from "./Legend";
import Control from "./Control";
import React from 'react'
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

function InfoBox(props) {
    return (
        <div className={"control-panel"} >
            <Box sx={{m:1}}>
                <Paper>
                    <Control defaultCollapsed={props.defaultCollapsed} mapRef={props.mapRef}/>
                    <Divider/>
                    <Legend/>
                </Paper>
            </Box>
        </div>
    )}

export default InfoBox
