import RoadChangeSubmitBox from './RoadChangeSubmitBox'
import {rideabilityIndex, waytypeDescription, surfaceDescription} from '../FeatureInfo'
import React from 'react'


export default function EditRoadSubmitBox(props) {
    var genDoc = (candidate, wayInfo, surfaceInfo, riInfo) => {
        console.log(candidate)
        var docToPost = {...candidate}
        docToPost.properties['old surface'] = surfaceDescription(docToPost.properties)
        docToPost.properties['old waytype'] = waytypeDescription(docToPost.properties)
        docToPost.properties['old ri'] = rideabilityIndex(docToPost.properties)
        docToPost.properties['surface'] = surfaceInfo
        docToPost.properties['waytype'] = wayInfo
        docToPost.properties['ri'] = riInfo
        docToPost.properties['edited'] = true
        docToPost.properties['verifications'] = 0
        delete docToPost['id']
    return docToPost}
    
    const candidateFeature = props.candidate
    const defaultSurface = surfaceDescription(candidateFeature.properties)
    const defaultWay = waytypeDescription(candidateFeature.properties)
    const defaultRi = rideabilityIndex(candidateFeature.properties)
    
    const onPost = (docToPost, _props) => {
        
        _props.setEditedData((previous)=>{
            docToPost['id'] = docToPost.properties['id']
            previous.features.push(docToPost)
            _props.mapRef.current.getMap().getSource('editedRoads').setData(previous)
            return previous
        })
        _props.resetCandidate()
    }

    return (
        <>
        <RoadChangeSubmitBox 
            wayInfo={defaultWay}
            surfaceInfo={defaultSurface}
            riInfo={defaultRi}
            {...props}
            onPost={onPost}
            postUrl="https://outforsk-api.azurewebsites.net/api/editWays?code=0S0AecprAeA1Muj1e8EfN7m2axIzhV6nZp6AZZ5zrFABF41r2lbD9A=="
            genDoc={genDoc}
            deletableObject={true}>
        </RoadChangeSubmitBox>
        </>
    )
}

