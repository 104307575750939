import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

function SetHome(props) {
    
    return (    
      <div className={"set-home"}>
        <Box
          
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          height='100%'
          
          sx={{p:2}}>
          <Button onClick={props.onClick} variant="contained" size="large">{props.buttonText}</Button>
        </Box>    
      </div>
    )
}

export default SetHome
