import React from 'react'
import Paper from '@mui/material/Paper'
import Image from './croppedbackground.png'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

function LoginPage() {
    return (
        <Paper
        sx={{
          height: '100%',
          backgroundImage: `url(${Image})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Grid container justifyContent="center" height='calc(100% - 32px)' >
            <Grid item xs={3} 
                  sx={{m:2,p:2,height:'100%',backgroundColor: 'rgba(255, 255, 255, 0.74)'}}>
                <Typography variant="h4" component="h2" align="center" mt={1}>Welcome to Outforsk</Typography>
                <Typography variant="subtitle1" align="center" mt={2}> The best place for exploring your local paths, tracks and backroads</Typography>
                
            </Grid>
        </Grid>
        
        </Paper>
    )
}

export default LoginPage
